import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { BadgeStyled } from './Badge.style';

const Badge = forwardRef(({ backgroundColor, color, center, children, ...props }, ref) => (
    <BadgeStyled
        center={center}
        color={color}
        backgroundColor={backgroundColor}
        {...props}
        ref={ref}
    >
        {children}
    </BadgeStyled>
));

Badge.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,

    /**
     * if true then the text will be centered in the badge
     */
    center: PropTypes.bool.isRequired,

    children: PropTypes.node,
};

Badge.defaultProps = {
    center: false,
};

export default Badge;
