import styled, { css } from 'styled-components';

import { P } from 'typography';
import { Button } from 'components/buttons';

import { IconLightbulb } from 'components/Icon';

export const TitleStyled = styled(P)`
    color: ${({ theme }) => theme.palette.primaryDark};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    display: flex;
    flex-direction: row;
`;

export const SubtitleStyled = styled(P)`
    color: ${({ theme }) => theme.palette.grey[500]};
    margin: 0;
`;

export const SubtitleBold = styled.span(
    ({ theme: { palette, fontWeights } }) => css`
        color: ${palette.grey[500]};
        font-weight: ${fontWeights.bold};
    `
);
export const IconLightbulbStyled = styled(IconLightbulb)`
    margin-right: ${({ theme }) => theme.spacing[1]};
`;

export const ContentContainerStyled = styled.div`
    padding: ${({ theme }) => ` ${theme.spacing[4]}`};
`;

export const CloseButtonStyled = styled(Button)`
    margin-top: ${({ theme }) => theme.spacing[4]};
`;

export const DialogArrowStyled = styled.div`
    display: none;
    background-color: white;
    width: ${({ theme }) => theme.spacing[2]};
    height: ${({ theme }) => theme.spacing[2]};
    position: absolute;
    top: -7px;
    left: 65px;
    border-radius: 2px;
    transform: rotate(45deg);
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        display: flex;
    }
`;
