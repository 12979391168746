import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BaseBadgeStyled } from './BaseBadge.style';

const BadgeStyled = styled(BaseBadgeStyled)`
    text-transform: none;

    ${({ theme }) => `
        background-color: ${theme.palette.lightYellow};
        color: ${theme.palette.yellow};
        font-size: ${theme.spacing['1.5']};
        line-height: ${theme.spacing['3']};
    `};
`;

const StarStyled = styled.span`
    color: ${({ theme }) => theme.palette.yellow};
`;

const PremiumOwnerFeatureBadge = ({ t, ...props }) => (
    <BadgeStyled {...props}>
        <StarStyled>{'\u2605 '}</StarStyled>
        {t('components_badges_premium_owner_feature')}
    </BadgeStyled>
);

PremiumOwnerFeatureBadge.propTypes = {
    t: func,
};

export const PremiumOwnerFeatureBadgeTest = PremiumOwnerFeatureBadge;
export default withTranslation()(PremiumOwnerFeatureBadge);
