/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import Badge from 'components/Badge';

const BadgeColumn = styled.span`
    display: flex;
    flex-direction: column;
`;

const BadgeRow = styled.span`
    display: flex;
`;

const BaseBadgeStyled = styled(Badge)`
    line-height: 1.6;
    padding-left: ${({ theme }) => theme.spacing[1]};
    padding-right: ${({ theme }) => theme.spacing[1]};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    ${BadgeRow} & {
        margin-right: ${({ theme }) => theme.spacing[1]};
        &:last-child {
            margin-right: 0;
        }
    }

    ${BadgeColumn} & {
        margin-bottom: ${({ theme }) => theme.spacing[1]};
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export { BaseBadgeStyled, BadgeColumn, BadgeRow };
