import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { track, Events } from 'utils/analytics';

import Dialog from 'components/Dialog';
import {
    ContentContainerStyled,
    CloseButtonStyled,
    TitleStyled,
    SubtitleStyled,
    SubtitleBold,
    IconLightbulbStyled,
    DialogArrowStyled,
} from './LowApplicantsInfoPrompt.style';

const LowApplicantsInfoPrompt = ({ t, positionTop, positionLeft, onCloseLowApplicantsPrompt }) => {
    const [isPromptOpen, setIsPromptOpen] = useState(true);

    const closeDialog = () => {
        track(Events.VIEWED_LOW_APPLICANTS_EDUCATIONAL_PROMPT.create());
        setIsPromptOpen(false);
        onCloseLowApplicantsPrompt();
    };

    return (
        <Dialog
            onCloseHandler={closeDialog}
            variant={Dialog.Variant.searchListingsFirstItemInfoPromptLOWAPPLICANTSABTEST}
            positionTop={positionTop}
            positionLeft={positionLeft}
            isOpen={isPromptOpen}
            blanketColor="rgba(0, 0, 0, 0.64)"
        >
            <DialogArrowStyled />
            <ContentContainerStyled>
                <TitleStyled>
                    <IconLightbulbStyled size="large" palette="primaryDark" />
                    {t('pages_searchListings_lowApplicants_info_title')}
                </TitleStyled>
                <SubtitleStyled>
                    <Trans
                        i18nKey="pages_searchListings_lowApplicants_info_subtitle"
                        components={{
                            bold: <SubtitleBold />,
                        }}
                    />
                </SubtitleStyled>
                <CloseButtonStyled fullWidth onClick={closeDialog}>
                    {t('pages_searchListings_lowApplicants_info_button')}
                </CloseButtonStyled>
            </ContentContainerStyled>
        </Dialog>
    );
};

LowApplicantsInfoPrompt.propTypes = {
    t: PropTypes.func.isRequired,
    onCloseLowApplicantsPrompt: PropTypes.func.isRequired,
    positionTop: PropTypes.number.isRequired,
    positionLeft: PropTypes.number.isRequired,
};

export default LowApplicantsInfoPrompt;
