import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BaseBadgeStyled } from './BaseBadge.style';

const BadgeStyled = styled(BaseBadgeStyled)`
    background-color: ${({ theme }) => theme.palette.accent};
    color: ${({ theme }) => theme.palette.white};
`;

const NewBadge = ({ t, ...props }) => (
    <BadgeStyled {...props}>{t('components_listingcard_badges_new')}</BadgeStyled>
);

NewBadge.propTypes = {
    t: func,
};

export const NewBadgeTest = NewBadge;
export default withTranslation()(NewBadgeTest);
