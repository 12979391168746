/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

const BadgeStyled = styled.span`
    display: inline-block;
    text-align: ${({ center }) => (center ? 'center' : 'right')};

    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    font-weight: 800;
    line-height: 1.6;
    text-transform: uppercase;

    padding-right: ${({ theme }) => theme.spacing[1]};
    ${({ center, theme }) => (center ? `padding-left: ${theme.spacing[1]}` : null)};
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
`;

export { BadgeStyled };
