import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { func, bool } from 'prop-types';
import { withTranslation } from 'react-i18next';

import { BaseBadgeStyled } from './BaseBadge.style';
import LowApplicantsInfoPrompt from './components/LowApplicantsInfoPrompt';

const BadgeStyled = styled(BaseBadgeStyled)`
    background-color: ${({ theme }) => theme.palette.accentLight};
    color: ${({ theme }) => theme.palette.accent};
`;

// Offsets to align the dialog/arrow to the low applicants badge
const TOP_OFFSET = 16;
const LEFT_OFFSET = 16;

const LowApplicants = ({
    t,
    showLowApplicantsPrompt,
    onCloseLowApplicantsPrompt,
    isSavedSearchDisplayRendered,
    ...props
}) => {
    const [badgePosition, setBadgePosition] = useState({ bottom: 500, left: 10 });
    const badgeRef = useRef();

    useEffect(() => {
        if (!showLowApplicantsPrompt) {
            return undefined;
        }

        const getBadgeBottom = () => {
            if (badgeRef.current) {
                const { bottom, left } = badgeRef.current.getBoundingClientRect();
                setBadgePosition({ bottom, left });
            }
        };
        getBadgeBottom();
        window.addEventListener('resize', getBadgeBottom);
        window.addEventListener('scroll', getBadgeBottom);
        return () => {
            window.removeEventListener('resize', getBadgeBottom);
            window.removeEventListener('scroll', getBadgeBottom);
        };
    }, [badgeRef, showLowApplicantsPrompt, isSavedSearchDisplayRendered]);

    return (
        <>
            <BadgeStyled {...props} ref={badgeRef}>
                {t('components_listingcard_badges_low_applicants')}
            </BadgeStyled>
            {showLowApplicantsPrompt && (
                <LowApplicantsInfoPrompt
                    t={t}
                    positionTop={badgePosition.bottom + TOP_OFFSET}
                    positionLeft={badgePosition.left - LEFT_OFFSET}
                    onCloseLowApplicantsPrompt={onCloseLowApplicantsPrompt}
                />
            )}
        </>
    );
};

LowApplicants.propTypes = {
    t: func.isRequired,
    showLowApplicantsPrompt: bool.isRequired,
    onCloseLowApplicantsPrompt: func.isRequired,
    isSavedSearchDisplayRendered: bool.isRequired,
};

export const LowApplicantsTest = LowApplicants;
export default withTranslation()(LowApplicantsTest);
