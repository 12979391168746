import React from 'react';
import styled from 'styled-components';
import { func } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { BaseBadgeStyled } from './BaseBadge.style';

const BadgeStyled = styled(BaseBadgeStyled)`
    background-color: ${({ theme }) => theme.palette.highlight};
    color: ${({ theme }) => theme.palette.primaryDark};
`;

const LastMinBadge = ({ t, ...props }) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BadgeStyled {...props}>{t('components_listingcard_badges_last_min')}</BadgeStyled>
);

LastMinBadge.propTypes = {
    // eslint-disable-next-line react/require-default-props
    t: func,
};

export const LastMinBadgeTest = LastMinBadge;
export default withTranslation()(LastMinBadgeTest);
